import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';

const Rsvp = (props) => {
  const [attending, setAttending] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [numberAttending, setNumberAttending] = useState(1);
  const [guestsAttending, setGuestsAttending] = useState(new Array(numberAttending).fill())
  const [phone, setPhone] = useState('')

  const onSubmit = (e) => {
    // console.log(e.target[e.target.length - 2])
    // e.preventDefault()
    if(submitted){
      e.preventDefault()
      console.log('blocked!')
      return
    }
    setSubmitted(true)
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    if(!attending){
      if(!regex.test(phone)){
        e.preventDefault()
        setSubmitted(false)
        e.target[6].setCustomValidity('Please enter a valid Phone Number.')
        e.target[6].reportValidity()
      }
    }else{
      console.log(e.target[3])
      console.log(e.target[4])
      console.log(e.target[5])

      for(let i = 0; i < guestsAttending.length; i++){
        if(!guestsAttending[i]){
          e.preventDefault()
          setSubmitted(false)
          let num = 3 + i
          if(num >= 4){
            num++
          }
          e.target[num].setCustomValidity('Please enter a name.')
          e.target[num].reportValidity()
        }
      }
      if(!regex.test(phone)){
        e.preventDefault()
        setSubmitted(false)
        e.target[e.target.length - 2].setCustomValidity('Please enter a valid Phone Number.')
        e.target[e.target.length - 2].reportValidity()
      }
    }
  };

  useEffect(() => {
    if (!attending) {
      setNumberAttendingHandler(0);
    }
    if (attending) {
      setNumberAttendingHandler(1);
    }
  }, [attending]);

  function setNumberAttendingHandler(num){
    if(!num){
      num = 0
    }
    if(num < numberAttending){
      setGuestsAttending([...guestsAttending.slice(0, num)])
    }else if(num > numberAttending){
      const diff = num - numberAttending
      setGuestsAttending([...guestsAttending, ...new Array(diff).fill()])
    }
    setNumberAttending(num)
    // console.log(guestsAttending)
  }

  function changeNames(e, i){
    if(!e.target.value.trim()){
      setGuestsAttending( ()=> guestsAttending.map((n,index)=>{
        if(index === i){
          return
        }else{
          return n
        }
      }))
    }else{
      setGuestsAttending( ()=> guestsAttending.map((n,index)=>{
        if(index === i + 1){
          return e.target.value
        }else{
          return n
        }
      }))
    }
  }
  
  useEffect(()=>{
    console.log(guestsAttending)
  }, [guestsAttending])

  // const submitButtonDisabled = !(name && numberAttending) || submitted;

  // wedding is over, disable the button
  const submitButtonDisabled = false;

  return (
    <Layout fullMenu location={props.location} title="RSVP">
      <article id="main">
        <header name="RSVP">
          <h2>RSVP</h2>
          <p>Phúc Đáp</p>
        </header>
        <section className="wrapper style5 rsvp">
          <iframe
            title="hidden_iframe"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: 'none' }}
            onLoad={() => {
              if (submitted) {
                navigate('/thankyou');
              }
            }}
          ></iframe>

          <div className="inner">
            <p>
              Kindly reply by <b>March 1st, 2023</b>.
            </p>
            <p>
              Để giúp chúng tôi thuận tiện cho việc sắp xếp xin vui lòng phúc
              đáp trước ngày <b>3.1.2023</b>.
            </p>
            <hr />
            <p>
              Any questions on filling out the form? Please feel free to call <b>512-287-9321</b>.
            </p>
            <form
              action="https://docs.google.com/forms/u/0/d/1vvksd3Scb2pslqoE_FrGOBW_EQgrh21BkWCx-lZNnvY/formResponse"
              onSubmit={onSubmit}
              method="post"
              target="hidden_iframe"
            >
              
              <div className="row gtr-uniform form-row">
                <div className="col-4 col-12-small">
                  <label className="label required-asterisk" htmlFor="name">
                    Will You Be Attending?
                  </label>
                  <div onChange={(e) => {
                    setAttending(e.target.value === 'Yes')
                    if(e.target.value === 'Yes'){
                      setNumberAttendingHandler(1)
                    }else{
                      setNumberAttendingHandler(0)
                    }
                    }}>
                    <input
                      type="radio"
                      id="attending-accept"
                      name="entry.877086558"
                      value="Yes"
                      defaultChecked
                    />
                    <label htmlFor="attending-accept">Attending</label>

                    <div className="col-4 col-12-small">
                      <input
                        type="radio"
                        id="attending-decline"
                        name="entry.877086558"
                        value="No"
                      />
                      <label htmlFor="attending-decline">
                        Regretfully Decline
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="row gtr-uniform form-row"
                style={{ display: attending ? 'block' : 'none' }}
              >
                <div className="col-6 col-12-xsmall">
                  <label className="label required-asterisk" htmlFor="number-of-attendees">
                    Total number of attendees / Số người tham dự
                  </label>
                  <select
                    name="entry.1498135098"
                    id="number-of-attendees"
                    value={numberAttending}
                    // placeholder="Number"
                    // value={numberAttending}
                    // min="0"
                    onChange={(e) => {
                      setNumberAttendingHandler(parseInt(e.target.value))
                    }}
                  >
                    {!attending? <option value="0">0</option> : ''}
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                  </select>
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-6 col-12-xsmall">
                  <label className="label required-asterisk" htmlFor="name">
                    {!attending ? "Name / Quý danh" : "Names of guests / Tên người tham dự"}
                  </label>
                  <input
                    type="text"
                    name="entry.1252401226"
                    id="name"
                    defaultValue=""
                    placeholder="Your First and Last Name"
                    required
                    onChange={(e) => {
                      setName(e.target.value)
                      setGuestsAttending(guestsAttending.map((x,i)=>i===0?e.target.value:x))
                      e.target.setCustomValidity('')
                    }}
                  />
                </div>
              </div>

              {/* Hidden Input */}
              <input
                type="text"
                name="entry.1424661284"
                id="name"
                defaultValue={name}
                style={{ display: 'none' }}
              />

              <div
                className="row form-row"
                style={{ display: attending ? 'block' : 'none' }}
              >
              <div className="col-6 col-12-xsmall">
                {numberAttending > 0 ? new Array(numberAttending - 1).fill().map((guest, i)=>
                    <input
                      key={i}
                      type="text"
                      name="entry.1424661284"
                      defaultValue=""
                      placeholder="First and Last Name"
                      onChange={e=>{
                        changeNames(e, i)
                        e.target.setCustomValidity('')
                      }}
                      />
                ) : ""}
                </div>
              </div>

              <div 
                className="row gtr-uniform form-row"
                style={{ display: attending ? 'block' : 'none' }}
              >
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="diet">
                    Dietary Restrictions / Chế Độ Ăn Kiêng
                  </label>
                  <textarea
                    name="entry.1751181340"
                    id="diet"
                    defaultValue=""
                    placeholder="List Restrictions Here"
                    onChange={(e) => e}
                  />
                </div>
              </div>

              <div 
                className="row gtr-uniform form-row"
              >
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="comments">
                    Additional Comments
                  </label>
                  <textarea
                    name="entry.227562281"
                    id="comments"
                    defaultValue=""
                    placeholder="Additional Comments"
                    onChange={(e) => e}
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-6 col-12-xsmall">
                  <label className="label required-asterisk" htmlFor="phone-number">
                    Phone Number / Điện Thoại
                  </label>
                  <input
                    type="text"
                    name="entry.825014554"
                    id="phone-number"
                    defaultValue=""
                    placeholder="(XXX) XXX-XXXX"
                    required
                    onChange={(e) => {
                      setPhone(e.target.value)
                      e.target.setCustomValidity('')
                    }}
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-12">
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Send"
                        className={`primary ${
                          submitButtonDisabled ? 'disabled' : ''
                        }`}
                      />
                    </li>
                    {/* <li>
                      <input type="reset" value="Reset" />
                    </li> */}
                  </ul>
                </div>
              </div>

            </form>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Rsvp;
